const customerType = document.getElementById("customer_type");

if(customerType){

    const singleMember = document.getElementById("single-member");
    const groupMember = document.getElementById("group-member");
    
    customerType.addEventListener("change", (e) => {
        if (e.currentTarget.checked) {
            groupMember.classList.remove('hidden');
            singleMember.classList.add('hidden');
        } else {
            groupMember.classList.add('hidden');
            singleMember.classList.remove('hidden');
        }

        e.target.value = e.currentTarget.checked;
    })
}